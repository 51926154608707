import React from "react"
import Layout from "../components/layout"

const IndexPage = ({ data }) => {
  return (
    <Layout landingPage="india-mumbai" />
  )
}

export default IndexPage
